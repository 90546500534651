import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Register.module.css';

import lightLogo from '../assets/lightlogo.svg';
import banner1 from '../assets/banner1.jpg';
import banner2 from '../assets/banner2.jpg';
import banner3 from '../assets/banner3.jpg';

const Register = () => {
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentBanner, setCurrentBanner] = useState(0); // Track current banner index
  const navigate = useNavigate();

  const banners = [banner1, banner2, banner3]; // Array of banner images

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, username, email, password })
      });

      const data = await response.json();
      if (response.ok) {
        console.log('Registration successful');
        navigate('/login');
      } else {
        setError(data.error || 'Registration failed');
      }
    } catch (error) {
      console.error('Registration error:', error);
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Rotate banner every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBanner((prevIndex) => (prevIndex + 1) % banners.length);
    }, 3000); // 3 seconds
    return () => clearInterval(interval); // Cleanup on component unmount
  }, [banners.length]);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        {/* Left Panel with Rotating Banner */}
        <div className={styles.leftPanel}>
          <div
            className={styles.skeletonImage}
            style={{ backgroundImage: `url(${banners[currentBanner]})` }} // Dynamic banner
          ></div>
        </div>

        {/* Right Panel with Register Form */}
        <div className={styles.rightPanel}>
          <div className={styles.registerCard}>
            <div className={styles.newLogo}>
              <img src={lightLogo} alt="Company Logo" />
            </div>

            <h2>Create a New Account</h2>

            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className={styles.input}
              />
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                className={styles.input}
              />
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={styles.input}
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className={styles.input}
              />
              <button type="submit" className={styles.registerBtn} disabled={loading}>
                {loading ? 'Registering...' : 'Register'}
              </button>
            </form>

            {error && <p className={styles.errorMessage}>{error}</p>}

            <p className={styles.loginLink}>
              Already have an account? <a href="/login">Login</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
