// src/LandingPage.js

import React from 'react';
import { useNavigate } from 'react-router-dom';  // Import the useNavigate hook
import './LandingPage.css';
import logo from '../assets/lightlogo.svg';
import banner from '../assets/banner.jpg';

function LandingPage() {
  const navigate = useNavigate();  // Initialize the navigate function

  const handleGoLive = () => {
    navigate('/videoroom');  // Navigate to the VideoRoom page
  };

  return (
    <div className="landing-page">
      {/* Header */}
      <header className="header">
        <div className="header-logo">
          <img src={logo} alt="Website Logo" />
        </div>
        <nav className="header-nav">
          <a href="#home">Home</a>
          <a href="#features">Features</a>
          <a href="#about">About</a>
          <a href="#contact">Contact</a>
        </nav>
        <div className="header-live">
          <button onClick={handleGoLive} className="go-live-button">Go Live</button>
        </div>
      </header>

      {/* Banner */}
      <div className="banner" style={{ backgroundImage: `url(${banner})` }}>
        <div className="banner-content">
          <h1>Welcome to LiveKit Video Chat</h1>
          <p>Connect with others in real-time video chats</p>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
