// src/redux/slices/userSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUserProfile, updateUserProfile } from '../../api/userAPI';

// Async thunk to fetch the user's profile
export const fetchProfile = createAsyncThunk('user/fetchProfile', async (token) => {
  const response = await fetchUserProfile(token);
  return response; // This will be stored in state
});

// Async thunk to update the user's profile
export const updateProfile = createAsyncThunk('user/updateProfile', async ({ token, userData }) => {
  const response = await updateUserProfile(token, userData);
  return response; // Updated user data
});

// Create the user slice
const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetUser: (state) => {
      state.user = null; // Reset user state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true; // Set loading state
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.loading = false; // Reset loading state
        state.user = action.payload; // Set the fetched user profile
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false; // Reset loading state
        state.error = action.error.message; // Handle error
      })
      .addCase(updateProfile.pending, (state) => {
        state.loading = true; // Set loading state
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loading = false; // Reset loading state
        state.user = action.payload; // Update the user profile in state
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false; // Reset loading state
        state.error = action.error.message; // Handle error
      });
  },
});

// Export the actions and the reducer
export const { resetUser } = userSlice.actions;
export default userSlice.reducer;
