import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './ResetPassword.module.css';

import lightLogo from '../assets/lightlogo.svg';
import banner1 from '../assets/banner1.jpg';
import banner2 from '../assets/banner2.jpg';
import banner3 from '../assets/banner3.jpg';

const ResetPassword = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [currentBanner, setCurrentBanner] = useState(0);
  const navigate = useNavigate();

  const banners = [banner1, banner2, banner3];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/reset-password/${token}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ newPassword })
      });

      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
        setTimeout(() => navigate('/login'), 3000); // Redirect to login after a delay
      } else {
        setMessage(data.error || 'Error resetting password');
      }
    } catch (error) {
      setMessage('Error resetting password');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBanner((prevIndex) => (prevIndex + 1) % banners.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [banners.length]);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        <div className={styles.leftPanel}>
          <div
            className={styles.skeletonImage}
            style={{ backgroundImage: `url(${banners[currentBanner]})` }}
          ></div>
        </div>

        <div className={styles.rightPanel}>
          <div className={styles.resetPasswordCard}>
            <div className={styles.newLogo}>
              <img src={lightLogo} alt="Company Logo" />
            </div>

            <h2>Reset Password</h2>

            <form onSubmit={handleSubmit}>
              <input
                type="password"
                placeholder="Enter your new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                className={styles.input}
              />
              <input
                type="password"
                placeholder="Confirm your new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className={styles.input}
              />
              <button type="submit" className={styles.resetBtn}>Reset Password</button>
            </form>

            {error && <p className={styles.errorMessage}>{error}</p>}
            {message && <p className={styles.message}>{message}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
