// src/components/Header.js

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { logout } from '../redux/slices/authSlice';
import './Header.css';

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // Access token from Redux to determine login status
    const { token } = useSelector((state) => state.auth);

    // Map routes to their respective titles
    const getPageTitle = (path) => {
        switch (path) {
            case '/home':
                return 'Home';
            case '/login':
                return 'LOGIN';
            case '/profile':
                return 'Profile';
            // Add other routes as needed
            default:
                return 'PAGE';
        }
    };

    const pageTitle = getPageTitle(location.pathname);

    // Button click handler
    const handleButtonClick = () => {
        if (token) {
            dispatch(logout()); // Log out the user
        } else {
            navigate('/login'); // Navigate to the login page if not logged in
        }
    };

    return (
        <header className="main-header">
            <div className="main-header-title-search">
                <h1>{pageTitle}</h1>
                <input type="text" className="main-header-search-box" placeholder="Search..." />
            </div>
            <button onClick={handleButtonClick} className="auth-button">
                {token ? 'Logout' : 'Login'}
            </button>
        </header>
    );
};

export default Header;
