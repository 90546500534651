// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import userReducer from './slices/userSlice'; // Import the new user reducer

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer, // Add the user reducer here
  },
});

export default store;
