// src/pages/Profile.js

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile, updateProfile } from '../redux/slices/userSlice';
import { refreshUser } from '../redux/slices/authSlice'; // Import refreshUser action
import Layout from '../components/Layout';
import './Profile.css';

const Profile = () => {
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.user);
  const [message, setMessage] = useState('');
  const [userData, setUserData] = useState({});
  const [profileImage, setProfileImage] = useState(null); 
  const [previewImage, setPreviewImage] = useState('');
  const fileInputRef = useRef();

  useEffect(() => {
    const token = localStorage.getItem('token');
    dispatch(fetchProfile(token));
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setUserData(user);
      setPreviewImage(user.profilePicture ? `${process.env.REACT_APP_BACKEND_URL}${user.profilePicture}` : '');
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleUpdate = () => {
    const token = localStorage.getItem('token');
    const updatedData = { ...userData };
    if (profileImage) {
      updatedData.profilePicture = profileImage;
    }
    dispatch(updateProfile({ token, userData: updatedData })).then((action) => {
      if (!action.error) { // Only refresh if the update was successful
        dispatch(refreshUser(action.payload)); // Update authSlice with new profile data
        setMessage('Profile updated successfully');
      }
    });
  };

  return (
    <Layout>
      <div className="profile-container">
        <h2>User Profile</h2>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {message && <p>{message}</p>}
            {error && <p>{error}</p>}

            {/* Top Section with Avatar, Name, and Email */}
            <div className="profile-header">
              <div className="avatar">
                <img src={previewImage} alt="Profile" className="profile-image" />
                <i
                  className="fas fa-camera camera-icon"
                  onClick={() => fileInputRef.current.click()}
                ></i>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
              </div>
              <div className="user-info">
                <h3>{userData.name || 'Your Name'}</h3>
                <p>{userData.email || 'Your Email'}</p>
              </div>
            </div>

            {/* Information Sections */}
            <div className="info-sections">
              <div className="info-box">
                <h3>Basic Information</h3>
                <input
                  name="name"
                  value={userData.name || ''}
                  onChange={handleChange}
                  placeholder="Name"
                />
                <input
                  name="username"
                  value={userData.username || ''}
                  onChange={handleChange}
                  placeholder="Username"
                />
                <input
                  name="phone"
                  value={userData.phone || ''}
                  onChange={handleChange}
                  placeholder="Phone"
                />
                <input
                  name="country"
                  value={userData.country || ''}
                  onChange={handleChange}
                  placeholder="Country"
                />
              </div>

              <div className="info-box">
                <h3>Academic Information</h3>
                <input
                  name="school"
                  value={userData.school || ''}
                  onChange={handleChange}
                  placeholder="School"
                />
                <input
                  name="major"
                  value={userData.major || ''}
                  onChange={handleChange}
                  placeholder="Major"
                />
                <select
                  name="academicLevel"
                  value={userData.academicLevel || ''}
                  onChange={handleChange}
                >
                  <option value="">Select Academic Level</option>
                  <option value="High School">High School</option>
                  <option value="Undergraduate">Undergraduate</option>
                  <option value="Graduate">Graduate</option>
                  <option value="Professional">Professional</option>
                </select>
                <input
                  name="subjects"
                  value={userData.subjects?.join(', ') || ''}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: 'subjects',
                        value: e.target.value.split(', '),
                      },
                    })
                  }
                  placeholder="Subjects (comma separated)"
                />
              </div>
            </div>

            <button onClick={handleUpdate}>Update Profile</button>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Profile;
