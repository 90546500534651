// src/utils/fetchToken.js

export async function fetchToken(identity, room) {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token?identity=${identity}&room=${room}`);
  const data = await response.json();

  if (response.ok) {
    return data.token;  // Return the token from the response
  } else {
    throw new Error(data.error || 'Failed to fetch token');
  }
}
