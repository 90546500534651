import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import './Layout.css';

const Layout = ({ children, user }) => { // Accept user as a prop
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  // Optional: Log the user info to confirm it updates
  useEffect(() => {
    console.log('User updated:', user);
  }, [user]);

  return (
    <div className="layout">
      <Sidebar
        isCollapsed={isSidebarCollapsed}
        user={user} // Pass user data to Sidebar
        toggleCollapse={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
      />
      <div className={`content ${isSidebarCollapsed ? 'collapsed' : 'expanded'}`}>
        <Header />
        <main className="main-content">{children}</main>
      </div>
    </div>
  );
};

export default Layout;
