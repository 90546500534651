// src/components/Sidebar.js

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './Sidebar.css';
import { FaHome, FaUserFriends, FaComments, FaUsers, FaTrophy, FaCog } from 'react-icons/fa';
import logo from '../assets/lightlogo.svg';
import onlyLogo from '../assets/onlylogo.svg';
import avatar from '../assets/avatar.png';
import { logout } from '../redux/slices/authSlice';

const Sidebar = ({ isCollapsed, toggleCollapse }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    // Get user details from Redux store
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    const handleAvatarClick = () => {
        if (user) setShowDropdown((prev) => !prev);
    };

    const handleOutsideClick = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setShowDropdown(false);
        }
    };

    const handleLogout = () => {
        dispatch(logout());
        setShowDropdown(false);
    };

    useEffect(() => {
        if (showDropdown) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
        return () => document.removeEventListener('mousedown', handleOutsideClick);
    }, [showDropdown]);

    return (
        <div className={`sidebar ${isCollapsed ? 'collapsed' : 'expanded'}`}>
            <div className="sidebar-container">
                
                {/* Website Logo */}
                <div className="logo-container">
                    <img src={isCollapsed ? onlyLogo : logo} alt="Website Logo" className={isCollapsed ? 'collapsed-logo' : 'expanded-logo'} />
                </div>
                
                <hr className="logo-divider" />

                <button className="collapse-btn" onClick={toggleCollapse} aria-label="Toggle Sidebar">
                    {isCollapsed ? '>' : '<'}
                </button>

                {/* Navigation Links */}
                <nav className="nav-links">
                    <div className="section-header">MAIN</div>
                    <Link to="/home" className="nav-item">
                        <FaHome className="nav-icon" />
                        {!isCollapsed && <span>Home</span>}
                    </Link>
                    <a href="/" className="nav-item">
                        <FaUserFriends className="nav-icon" />
                        {!isCollapsed && <span>Friends</span>}
                    </a>
                    <a href="/" className="nav-item">
                        <FaComments className="nav-icon" />
                        {!isCollapsed && <span>Chats</span>}
                    </a>
                    <a href="/" className="nav-item">
                        <FaUsers className="nav-icon" />
                        {!isCollapsed && <span>Groups</span>}
                    </a>
                    <a href="/" className="nav-item">
                        <FaTrophy className="nav-icon" />
                        {!isCollapsed && <span>Leaderboard</span>}
                    </a>

                    <hr className="divider" />

                    <div className="section-header">SETTINGS</div>
                    <a href="/" className="nav-item">
                        <FaCog className="nav-icon" />
                        {!isCollapsed && <span>Settings</span>}
                    </a>
                </nav>

                {/* User Profile at Bottom */}
                <div className="user-profile" onClick={handleAvatarClick}>
                    <img
                        src={user?.profilePicture ? `http://localhost:5000${user.profilePicture}` : avatar}
                        alt="User Avatar"
                        className="user-avatar"
                        onError={(e) => (e.target.src = avatar)} // Fallback to default avatar
                    />
                    {!isCollapsed && (
                        <div className="user-info">
                            {user ? (
                                <>
                                    <p className="user-name">{user.username}</p>
                                    <p className="user-email">{user.email}</p>
                                </>
                            ) : (
                                <>
                                    <p className="guest-label">Guest</p>
                                    <div className="auth-links">
                                        <Link to="/login" className="auth-link">Login</Link>
                                        <span> | </span>
                                        <Link to="/signup" className="auth-link">Sign Up</Link>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>

                {/* Dropdown Menu */}
                {user && showDropdown && (
                    <div
                        ref={dropdownRef}
                        className="dropdown-menu"
                        style={{
                            left: isCollapsed ? '75px' : '210px',
                            top: '80px'
                        }}
                    >
                        <Link to="/profile" className="dropdown-item">View Profile</Link>
                        <Link to="/stats" className="dropdown-item">Stats</Link>
                        <hr className="dropdown-divider" />
                        <Link to="/profile-settings" className="dropdown-item">Profile Setting</Link>
                        <Link to="/privacy-settings" className="dropdown-item">Privacy Setting</Link>
                        <Link to="/account-settings" className="dropdown-item">Account Setting</Link>
                        <Link to="/manage-subscription" className="dropdown-item">Manage Subscription</Link>
                        <hr className="dropdown-divider" />
                        <span onClick={handleLogout} className="dropdown-item" style={{ cursor: 'pointer' }}>Logout</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Sidebar;