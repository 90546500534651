import React, { useState, useEffect } from 'react';
import styles from './ForgotPassword.module.css';

import lightLogo from '../assets/lightlogo.svg';
import banner1 from '../assets/banner1.jpg';
import banner2 from '../assets/banner2.jpg';
import banner3 from '../assets/banner3.jpg';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [currentBanner, setCurrentBanner] = useState(0); // Track current banner index

  const banners = [banner1, banner2, banner3]; // Array of banner images

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });

      const data = await response.json();
      setMessage(data.message || 'Error sending reset email');
    } catch (error) {
      setMessage('Error sending reset email');
    }
  };

  // Rotate banner every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBanner((prevIndex) => (prevIndex + 1) % banners.length);
    }, 3000); // 3 seconds
    return () => clearInterval(interval); // Cleanup on component unmount
  }, [banners.length]);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        {/* Left Panel with Rotating Banner */}
        <div className={styles.leftPanel}>
          <div
            className={styles.skeletonImage}
            style={{ backgroundImage: `url(${banners[currentBanner]})` }}
          ></div>
        </div>

        {/* Right Panel with Forgot Password Form */}
        <div className={styles.rightPanel}>
          <div className={styles.forgotPasswordCard}>
            <div className={styles.newLogo}>
              <img src={lightLogo} alt="Company Logo" />
            </div>

            <h2>Forgot Password</h2>

            <form onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={styles.input}
              />
              <button type="submit" className={styles.resetBtn}>Send Reset Link</button>
            </form>

            {message && <p className={styles.message}>{message}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
