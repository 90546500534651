import React, { useState, useEffect } from 'react';
import { Room, RoomEvent, createLocalVideoTrack, createLocalAudioTrack } from 'livekit-client';
import { fetchToken } from '../api/videoAPI';
import { useSelector } from 'react-redux'; // Import to access Redux state
import { useLocation } from 'react-router-dom'; // Import to read query parameters
import { ReactComponent as VideoPlaceholder } from '../assets/videoroom-placeholder.svg'; // Import SVG as React component
import { FaCamera, FaMicrophone, FaArrowLeft, FaArrowRight, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa'; // Import arrow icons
import './VideoRoom.css';  // Import the CSS for layout

const VideoRoom = () => {
  const location = useLocation();
  const username = useSelector((state) => state.auth.user?.username);
  const [roomName, setRoomName] = useState('');
  const [livekitRoom, setLivekitRoom] = useState(null);
  const [participants, setParticipants] = useState([]);  // Track participants
  const [localVideoTrack, setLocalVideoTrack] = useState(null);  // Track local video
  const [localAudioTrack, setLocalAudioTrack] = useState(null);  // Track local audio
  const [isVideoOn, setIsVideoOn] = useState(false);  // Video toggle state
  const [isAudioOn, setIsAudioOn] = useState(false);  // Audio toggle state
  const [hasJoined, setHasJoined] = useState(false); // Track if the user has joined the room

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const videosPerPage = 11;

  // Extract room name from the URL query parameters or default to "Study Room"
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const room = params.get('room');
    setRoomName(room ? room : 'Study Room');
  }, [location.search]);

  // Automatically join the room when the component mounts
  useEffect(() => {
    if (username && roomName) {
      handleJoinRoom();
    }
  }, [username, roomName]);

  // Handle joining the room
  const handleJoinRoom = async () => {
    if (!username) {
      alert('Username is required!');
      return;
    }

    try {
      const generatedToken = await fetchToken(username, roomName);

      const newRoom = new Room();
      await newRoom.connect(process.env.REACT_APP_LIVEKIT_WEBSOCKET_URL, generatedToken);

      setLivekitRoom(newRoom);
      setHasJoined(true);

      newRoom.participants.forEach((participant) => {
        subscribeToParticipant(participant);
      });

      newRoom.on(RoomEvent.ParticipantConnected, (participant) => {
        subscribeToParticipant(participant);
      });

      newRoom.on(RoomEvent.ParticipantDisconnected, (participant) => {
        setParticipants((prevParticipants) =>
          prevParticipants.filter((p) => p.identity !== participant.identity)
        );
      });
    } catch (error) {
      console.error('Error connecting to LiveKit:', error);
    }
  };

  // Function to subscribe to a participant's tracks
  const subscribeToParticipant = (participant) => {
    setParticipants((prevParticipants) => [
      ...prevParticipants,
      { identity: participant.identity, tracks: [] },
    ]);

    participant.tracks.forEach((publication) => {
      if (publication.track) {
        handleTrackSubscribed(publication.track, participant);
      }
    });

    participant.on('trackSubscribed', (track) => {
      handleTrackSubscribed(track, participant);
    });

    participant.on('trackUnpublished', (publication) => {
      if (publication.kind === 'video') {
        setParticipants((prevParticipants) => {
          const updatedParticipants = prevParticipants.map((p) => {
            if (p.identity === participant.identity) {
              return { ...p, tracks: p.tracks.filter((t) => t.kind !== 'video') };
            }
            return p;
          });
          return updatedParticipants;
        });
      }
    });
  };

  const handleTrackSubscribed = (track, participant) => {
    if (track.kind === 'video') {
      setParticipants((prevParticipants) =>
        prevParticipants.map((p) =>
          p.identity === participant.identity
            ? { ...p, tracks: [...p.tracks, track] }
            : p
        )
      );
    } else if (track.kind === 'audio') {
      setParticipants((prevParticipants) =>
        prevParticipants.map((p) =>
          p.identity === participant.identity
            ? { ...p, audioTrack: track }
            : p
        )
      );
    }
  };

  // Handle toggling video on/off
  const handleToggleVideo = async () => {
    if (isVideoOn) {
      if (localVideoTrack) {
        localVideoTrack.stop();
        setLocalVideoTrack(null);
        livekitRoom.localParticipant.unpublishTrack(localVideoTrack);
      }
    } else {
      const newVideoTrack = await createLocalVideoTrack();
      setLocalVideoTrack(newVideoTrack);
      livekitRoom.localParticipant.publishTrack(newVideoTrack);
    }
    setIsVideoOn(!isVideoOn);
  };

  // Handle toggling audio on/off
  const handleToggleAudio = async () => {
    if (isAudioOn) {
      if (localAudioTrack) {
        localAudioTrack.stop();
        setLocalAudioTrack(null);
        livekitRoom.localParticipant.unpublishTrack(localAudioTrack);
      }
    } else {
      const newAudioTrack = await createLocalAudioTrack();
      setLocalAudioTrack(newAudioTrack);
      livekitRoom.localParticipant.publishTrack(newAudioTrack);
    }
    setIsAudioOn(!isAudioOn);
  };

  // Pagination Logic
  const indexOfLastParticipant = currentPage * videosPerPage;
  const indexOfFirstParticipant = indexOfLastParticipant - videosPerPage;
  const currentParticipants = participants.slice(indexOfFirstParticipant, indexOfLastParticipant);

  const nextPage = () => {
    if (currentPage < Math.ceil(participants.length / videosPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(Math.ceil(participants.length / videosPerPage));
  };

  useEffect(() => {
    return () => {
      if (livekitRoom) {
        livekitRoom.disconnect();
      }
    };
  }, [livekitRoom]);

  return (
    <div className="main-container">
      <div className="video-container">
        {hasJoined && !isVideoOn ? (
          <div className="video-wrapper">
            <VideoPlaceholder className="video-placeholder-image" />
            <div className="name-label">{username}</div>
          </div>
        ) : (
          hasJoined && (
            <div className="video-wrapper">
              <video
                ref={(el) => {
                  if (el && localVideoTrack) {
                    el.srcObject = new MediaStream([localVideoTrack.mediaStreamTrack]);
                    el.autoplay = true;
                    el.muted = true;
                  }
                }}
                className="video-element"
              />
              <div className="name-label">{username}</div>
            </div>
          )
        )}

        {currentParticipants.map((participant) => (
          <div key={participant.identity} className="video-wrapper">
            {participant.tracks.length > 0 ? (
              participant.tracks.map((track, index) => {
                return track.kind === 'video' ? (
                  <video
                    key={index}
                    ref={(el) => {
                      if (el && track) {
                        el.srcObject = new MediaStream([track.mediaStreamTrack]);
                        el.autoplay = true;
                      }
                    }}
                    className="video-element"
                  />
                ) : null;
              })
            ) : (
              <VideoPlaceholder className="video-placeholder-image" />
            )}
            <div className="name-label">{participant.identity}</div>
          </div>
        ))}
      </div>

      <div className="button-container">
        <button onClick={handleToggleVideo}>
          <FaCamera className={isVideoOn ? '' : 'off-icon'} />
        </button>
        <button onClick={handleToggleAudio}>
          <FaMicrophone className={isAudioOn ? '' : 'off-icon'} />
        </button>

        {/* Pagination buttons */}
        <button onClick={handleFirstPage} disabled={currentPage === 1}>
          <FaAngleDoubleLeft />
        </button>

        <button onClick={prevPage} disabled={currentPage === 1}>
          <FaArrowLeft />
        </button>

        <span>{currentPage}</span>

        <button
          onClick={nextPage}
          disabled={currentPage === Math.ceil(participants.length / videosPerPage)}
        >
          <FaArrowRight />
        </button>

        <button
          onClick={handleLastPage}
          disabled={currentPage === Math.ceil(participants.length / videosPerPage)}
        >
          <FaAngleDoubleRight />
        </button>
      </div>
    </div>
  );
};

export default VideoRoom;
