import React from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Import to get the username from the Redux store

const Home = () => {
  const navigate = useNavigate();
  const username = useSelector((state) => state.auth.user?.username); // Accessing username from Redux store

  // Handle room navigation with dynamically setting room name and identity (username)
  const handleRoomNavigation = (roomName) => {
    if (!username) {
      alert('You need to be logged in to join a room.');
      navigate('/login'); // Redirect to login if needed
      return;
    }

    // Navigate with a minimal delay to ensure user interaction is detected
    setTimeout(() => {
      navigate(`/videoroom?room=${encodeURIComponent(roomName)}&identity=${username}`);
    }, 0);
  };

  return (
    <div className="home-container">
      <h2>Welcome to the Home Page, {username}!</h2>
      <p>This is the main page of the application. Use the navigation to explore other features.</p>

      <div className="content-container">
        {/* Room selection */}
        <div className="room-selection-container">
          <h3>Join a Room</h3>
          <ul className="room-list">
            <li className="room-item" onClick={() => handleRoomNavigation('Study Room')}>Study Room</li>
            <li className="room-item" onClick={() => handleRoomNavigation('Focus Room')}>Focus Room</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Home;
