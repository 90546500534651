// src/api/userAPI.js

const API_URL = process.env.REACT_APP_BACKEND_URL; // Base URL for your backend API

// Register a new user
export const registerUser = async (name, username, email, password) => {
  const response = await fetch(`${API_URL}/auth/register`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name, username, email, password })
  });

  if (!response.ok) {
    const error = await response.json();
    console.error('User Registration Error:', error);
    throw new Error(error.message || 'Failed to register user');
  }

  return response.json();
};

// Function to fetch the logged-in user's profile
export const fetchUserProfile = async (token) => {
  const response = await fetch(`${API_URL}/auth/profile`, {
    headers: { Authorization: `Bearer ${token}` }
  });

  if (!response.ok) {
    const error = await response.json();
    console.error('Fetch User Profile Error:', error);
    throw new Error(error.message || 'Failed to fetch user profile');
  }

  return response.json();
};

// Function to update the logged-in user's profile
export const updateUserProfile = async (token, userData) => {
  const formData = new FormData();

  // Append each userData field to FormData
  for (const key in userData) {
    if (userData[key]) {
      formData.append(key, userData[key]);
    }
  }

  // Make the PUT request using FormData
  const response = await fetch(`${API_URL}/auth/profile`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`
      // No need to set 'Content-Type' here; fetch will set it automatically for FormData
    },
    body: formData,
  });

  if (!response.ok) {
    const error = await response.json();
    console.error('Update User Profile Error:', error);
    throw new Error(error.message || 'Failed to update user profile');
  }

  return response.json();
};
