// src/redux/slices/authSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Helper function to safely parse JSON
const parseUserFromLocalStorage = () => {
  try {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  } catch (error) {
    console.error("Error parsing user data from localStorage:", error);
    return null;
  }
};

// Async action for login
export const login = createAsyncThunk('auth/login', async ({ email, password }, { rejectWithValue }) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password })
    });
    const data = await response.json();
    
    if (!response.ok) throw new Error(data.error || 'Login failed');

    // Save token and user in localStorage
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data.user)); // Store full user data as a string in localStorage

    console.log("Login action payload:", { token: data.token, user: data.user }); // Log to verify payload
    return { token: data.token, user: data.user };
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: parseUserFromLocalStorage(), // Use safe parsing for user data
    token: localStorage.getItem('token') || null,
    loading: false,
    error: null
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem('token');
      localStorage.removeItem('user'); // Remove user from localStorage on logout
      console.log("User logged out, state reset"); // Log to confirm logout
    },
    refreshUser: (state, action) => {
      // Update user data in the state and localStorage
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload)); // Sync with localStorage
      console.log("User profile refreshed in authSlice:", action.payload); // Log to confirm update
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
        state.user = action.payload.user;
        console.log('Redux state after login:', state); // Log to confirm user and token are set
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { logout, refreshUser } = authSlice.actions;
export default authSlice.reducer;
